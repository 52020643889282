import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

// markup
const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <main>
      <div className="wrapper-body" id="not-found">
        <div
          className="container main-text"
          style={{
            paddingTop: '350px',
            paddingBottom: '350px',
          }}
        >
          <h1>Page not found</h1>
          <p>
            Sorry, we couldn’t find what you were looking for.
            <br />
            <Link to="/">Go home</Link>.
          </p>
        </div>
      </div>
    </main>
  </Layout>
);

export default NotFoundPage;
